import { Fragment, useEffect, useState } from 'react';
import useWindowSize from '../hooks/useWindowSize';
import MultipleChoice from './MultipleChoice';
import axios from 'axios';

function Voting() {
    const [state, setState] = useState({
        stand: -1,
        drink: '',
        taste: -1,
        cost: -1,
        ambient: -1,
        service: -1,
        recommendation: -1,
    });

    const [errors, setErrors] = useState({
        s1: false,
        s2: false,
        s3: false,
        s4: false,
        s5: false,
        s6: false,
        s7: false,
    });

    const reset = (e) => {
        setState({
            stand: -1,
            drink: '',
            taste: -1,
            cost: -1,
            ambient: -1,
            service: -1,
            recommendation: -1,
        });

        setErrors({
            s1: false,
            s2: false,
            s3: false,
            s4: false,
            s5: false,
            s6: false,
            s7: false,
        });

        setStep(1);
    };

    const size = useWindowSize();

    const [progress, setProgress] = useState((1 / 7) * 100);

    const [step, setStep] = useState(1);

    const stands = [
        'Neuer Markt: Kaffee Rundblick',
        'Neuer Markt: Glühweinhütte Meisel',
        'Neuer Markt - Glühwein Peters',
        "Neuer Markt – Glühweintreff (gegenüber Break-Dancer)",
        "Neuer Markt – Kirschglühwein (gegenüber Sparkasse (Rauchwurst)",
        'Einkaufsstraße - Marine Technik Schule',
        'Einkaufsstraße - Glühweinmühle Thomas Holtz',
        'Rathauskeller - WasserstoffBar (nur bis 19:00 Uhr)',
        'Rathauskeller - Die Waffel (nur bis 19:00 Uhr)',
        "Alter Markt - Logstoff",
        'Alter Markt - Glühweinhütte',
        'Alter Markt - Omis Glühwein',
        'Alter Markt - Schluck und Happen',
        'Alter Markt - Glühwein Kogge',
        'Fährstraße - Scheelehof (nur bis 20:00 Uhr)',
    ];
    
    const tastes = ['War ungenießbar', 'Schlechter als erwartet', 'Okay', 'Besser als Erwartet', 'Ausgezeichnet'];
    const costs = ['Dafür viel zu teuer', 'zu teuer', 'Passt', 'Günstig', 'Dafür hätte ich auch mehr bezahlt'];
    const ambients = [
        'Das war garnicht meins',
        'Habe mich nicht so wohlgefühlt',
        'Okay',
        'Hat mir gut gefallen',
        'Super',
    ];
    const services = [
        'Hatte keinen Plan oder war sehr unhöflich',
        'Könnte besser sein',
        'Okay',
        'War echt gut',
        'Das hat mir sehr gut gefallen',
    ];
    const recommendations = [
        'Absolut nicht zu empfehlen',
        'Unwahrscheinlich',
        'Vielleicht',
        'Wenn mich jemand fragt dann ja',
        'Ich werde es jedem empfehlen',
    ];

    const selectField = (name, idx) => {
        setState({ ...state, [name]: idx });
    };

    const validate = () => {
        if (step === 1 && state.stand === -1) {
            setErrors({ ...errors, s1: true });
            return false;
        }
        if (step === 2 && state.drink === '') {
            setErrors({ ...errors, s2: true });
            return false;
        }
        if (step === 3 && state.taste === -1) {
            setErrors({ ...errors, s3: true });
            return false;
        }
        if (step === 4 && state.cost === -1) {
            setErrors({ ...errors, s4: true });
            return false;
        }
        if (step === 5 && state.ambient === -1) {
            setErrors({ ...errors, s5: true });
            return false;
        }
        if (step === 6 && state.service === -1) {
            setErrors({ ...errors, s6: true });
            return false;
        }
        setErrors({
            s1: false,
            s2: false,
            s3: false,
            s4: false,
            s5: false,
            s6: false,
            s7: false,
        });
        return true;
    };

    const nextStep = (e) => {
        if (validate()) {
            setStep(step + 1);
        }
    };

    const prevStep = (e) => {
        setStep(step - 1);
    };

    useEffect(() => {
        setProgress((step / 7) * 100);
    }, [step]);

    const change = (e) => {
        setState({ ...state, [e.target.name]: e.target.value });
    };

    const submit = async (e) => {
        e.preventDefault();
        if (step === 7 && state.recommendation === -1) {
            setErrors({ ...errors, s7: true });
            return false;
        }
        try {
            console.log(state);
            await axios.post('/api/v1/voting', state);
            setStep(8);
        } catch (err) {
            setStep(9);
        }
        return false;
    };

    return (
        <div className='wrapper_in'>
            <div className='container-fluid'>
                <div className='subheader' id='quote'></div>
                <div className='row'>
                    <aside
                        className='col-xl-3 col-lg-4'
                        style={{ display: size.width < 992 && step > 1 ? 'none' : 'block' }}
                    >
                        <h2>Wer ist Dein Glühweinkönig?</h2>
                        <p className='lead'>Bewerte hier den besten Glühwein auf dem Weihnachtsmarkt Stralsund.</p>
                        <ul className='list_ok'>
                            <li>Wähle deinen Lieblingsstand.</li>
                            <li>Sag uns, was du getrunken hast.</li>
                            <li>Bewerte deinen Gesamteindruck.</li>
                        </ul>
                        <p className='lead'>
                            <a target={'_blank'} href='https://www.stralsunder-weihnachtsmarkt.de/standorte/'>
                                Hier ist eine Karte der Stände.
                            </a>
                        </p>
                    </aside>
                    <div className='col-xl-9 col-lg-8'>
                        <div id='wizard_container' className='wizard'>
                            <div id='top-wizard'>
                                <strong>Fortschritt</strong>
                                <div
                                    id='progressbar'
                                    className='ui-progressbar ui-widget ui-widget-content ui-corner-all'
                                    role='progressbar'
                                    aria-valuemin='0'
                                    aria-valuemax='100'
                                    aria-valuenow='0'
                                >
                                    <div
                                        className='ui-progressbar-value ui-widget-header ui-corner-left'
                                        style={{ width: progress + '%' }}
                                    ></div>
                                </div>
                            </div>

                            <form onSubmit={(e) => submit(e)}>
                                <div id='middle-wizard' className='wizard-branch wizard-wrapper'>
                                    {step === 1 && (
                                        <MultipleChoice
                                            title='Welchen Stand hast Du besucht?'
                                            step='1/7'
                                            values={stands}
                                            select={selectField}
                                            name='stand'
                                            state={state}
                                            error={errors.s1}
                                        />
                                    )}
                                    {step === 2 && (
                                        <div className='step wizard-step'>
                                            <h3 className='main_question wizard-header'>
                                                <strong>2/7</strong>Was hast Du getrunken? (Glühwein, Punsch, ...)
                                            </h3>
                                            <div className='col-sm-12'>
                                                <div className='form-group'>
                                                    <input
                                                        type='text'
                                                        name='drink'
                                                        id='drink'
                                                        className='form-control'
                                                        placeholder='(Glühwein, Punsch, ...)'
                                                        onChange={(e) => change(e)}
                                                        value={state.drink}
                                                    />
                                                    {errors.s2 === true && (
                                                        <span htmlFor='drink' className='error'>
                                                            Erforderlich
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {step === 3 && (
                                        <MultipleChoice
                                            title='Wie gut hat Dir das Getränk geschmeckt?'
                                            step='3/7'
                                            values={tastes}
                                            select={selectField}
                                            name='taste'
                                            state={state}
                                            error={errors.s3}
                                        />
                                    )}
                                    {step === 4 && (
                                        <MultipleChoice
                                            title='Wie schätzt Du das Preis-Leistungsverhältnis ein?'
                                            step='4/7'
                                            values={costs}
                                            select={selectField}
                                            name='cost'
                                            state={state}
                                            error={errors.s4}
                                        />
                                    )}
                                    {step === 5 && (
                                        <MultipleChoice
                                            title='Wie hat Dir das Ambiente gefallen?'
                                            step='5/7'
                                            values={ambients}
                                            select={selectField}
                                            name='ambient'
                                            state={state}
                                            error={errors.s5}
                                        />
                                    )}
                                    {step === 6 && (
                                        <MultipleChoice
                                            title='Wie organisiert und freundlich war die Bedienung?'
                                            step='6/7'
                                            values={services}
                                            select={selectField}
                                            name='service'
                                            state={state}
                                            error={errors.s6}
                                        />
                                    )}
                                    {step === 7 && (
                                        <MultipleChoice
                                            title='Wie wahrscheinlich ist Deine Weiterempfehlung des Glühweinstandes?'
                                            step='7/7'
                                            values={recommendations}
                                            select={selectField}
                                            name='recommendation'
                                            state={state}
                                            error={errors.s7}
                                        />
                                    )}
                                    {step === 8 && (
                                        <Fragment>
                                            <h2>Vielen Dank</h2>
                                            <p className='lead'>Wir wünschen noch einen schönen Aufenthalt.</p>
                                            <ul className='list_ok'>
                                                <li>
                                                    Die Auswertung erfährst du, wenn der Weihnachtsmarkt beendet ist.
                                                </li>
                                            </ul>
                                        </Fragment>
                                    )}
                                    {step === 9 && (
                                        <Fragment>
                                            <h2>Fehler</h2>
                                            <p className='lead'>
                                                Es ist ein Fehler aufgetreten. Versuche es später erneut.
                                            </p>
                                        </Fragment>
                                    )}
                                </div>
                                <div id='bottom-wizard'>
                                    <button
                                        type='button'
                                        onClick={(e) => reset(e)}
                                        className='backward'
                                        disabled={step === 1}
                                    >
                                        Reset
                                    </button>
                                    <button
                                        type='button'
                                        onClick={(e) => prevStep(e)}
                                        name='backward'
                                        className='backward'
                                        disabled={step === 1 || step > 7}
                                    >
                                        Zurück
                                    </button>
                                    <button
                                        type='button'
                                        name='forward'
                                        className='forward'
                                        onClick={(e) => nextStep(e)}
                                        disabled={step >= 7}
                                    >
                                        Weiter
                                    </button>
                                    <button type='submit' className='submit' disabled={step !== 7}>
                                        Abschicken
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Voting;
